<template>
	<div>
		<h2 class="content-block">
			<img src="@/assets/reg_form_header.png" class="header-image" />
			<div class="header-title">Registration Form</div>
			<div class="header-subtitle">(Enhancement - Intensive Review Program)</div>
		</h2>

		<div class="content-block dx-card responsive-paddings application-form">
			<form @submit.prevent="submitApplicationForm" enctype="multipart/form-data">
				<DxForm label-mode="floating" :col-count="3" :form-data="applicationData">
					<template #groupHeading="{ data }">
						<i :class="'dx-icon dx-icon-' + data.caption.split(';')[0]"/><span>{{ data.caption.split(';')[1] }}</span>
					</template>
					<DxGroupItem caption="globe;School and course" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="school" editor-type="dxSelectBox" :editor-options="schoolsListOptions" :is-required="true" />
						<DxSimpleItem data-field="branch" editor-type="dxSelectBox" :editor-options="branchListOptions" :is-required="true" />
						<DxSimpleItem data-field="course" editor-type="dxSelectBox" :editor-options="courseListOptions" :is-required="true" />
						<DxSimpleItem data-field="review_program" editor-type="dxSelectBox" :editor-options="programListOptions" :is-required="true" />
						<DxSimpleItem v-if="needsBoardExamDate" data-field="board_exam_date" editor-type="dxDateBox" :editor-options="{ type: 'date', pickerType: 'native' }" :is-required="true" />
					</DxGroupItem>
					<DxGroupItem caption="user;Name and personal details" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="last_name" :is-required="true" :label="{ text: 'Last/Family name' }" />
						<DxSimpleItem data-field="first_name" :is-required="true" />
						<DxSimpleItem data-field="middle_name" :is-required="true" />
						<DxSimpleItem data-field="gender" editor-type="dxSelectBox" :editor-options="genderListOptions" :is-required="true" />
						<DxSimpleItem data-field="birthday" editor-type="dxDateBox" :editor-options="{ type: 'date', pickerType: 'native', max: Date() }" :is-required="true" />
					</DxGroupItem>
					<DxGroupItem caption="tel;Contact details" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="mobile" :is-required="true" :editor-options="{ mode: 'tel', mask: '\\0\\900-000-0000', maskInvalidMessage: 'Mobile must be like 0912-345-6789' }">
							<DxRequiredRule message="Mobile is required" />
						</DxSimpleItem>
						<DxSimpleItem data-field="email" :is-required="true">
							<DxRequiredRule message="Email is required"/><DxEmailRule message="Email is invalid"/>
						</DxSimpleItem>
						<DxSimpleItem data-field="facebook" :is-required="true" :label="{ text: 'Facebook Messenger' }" />
						<DxSimpleItem data-field="address" :is-required="true" :col-span="3" editor-type="dxTextArea" :editor-options="{ autoResizeEnabled: true, maxHeight: '180px' }" />
					</DxGroupItem>
					<DxGroupItem caption="errorcircle;In case of emergency, please notify:" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="emergency_name" :label="{ text: 'Full name' }" :is-required="true" />
						<DxSimpleItem data-field="emergency_phone" :label="{ text: 'Phone number' }" :is-required="true" />
						<DxSimpleItem data-field="emergency_relationship" :label="{ text: 'Relationship' }" :is-required="true" />
						<DxSimpleItem data-field="emergency_address" :label="{ text: 'Address to contact' }" :is-required="true" :col-span="3" editor-type="dxTextArea" :editor-options="{ autoResizeEnabled: true, maxHeight: '180px' }" />
					</DxGroupItem>
					<DxGroupItem caption="image;Photos and files" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="photo" template="uploadFileDrop" />
					</DxGroupItem>
					<template #uploadFileItem="{ data }">
						<DxFileUploader :name="data.dataField"
							select-button-text="Select photo" :label-text="data.dataField" accept="image/*"
							upload-mode="useForm" :input-attr="{ 'aria-label': 'Select Photo' }"
							@valueChanged="fileUploadChanged($event, data.dataField)"
						/>
					</template>
					<template #uploadFileDrop="{ data }">
						<DropzoneFile :label="data.editorOptions.label" />
					</template>
					<DxEmptyItem :col-span="2" />
					<DxButtonItem :col-span="3" :button-options="{ type: 'success', text: 'Submit', useSubmitBehavior: true }" />
				</DxForm>
			</form>
			<DxLoadPanel :position="{ of: '.application-form' }" :shading="true" shadingColor="rgba(0,0,0,0.4)" v-model:visible="loadingVisible" />
		</div>
	</div>
</template>

<script>
import { DxForm, DxSimpleItem , DxGroupItem, DxButtonItem, DxEmptyItem, DxRequiredRule, DxEmailRule } from "devextreme-vue/form";
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import DropzoneFile from '../components/dropzone-file';
import { inject, ref, computed } from "vue";
import to from 'await-to-js';

export default {
	setup() {
		const $http = inject('$http');
		const colCountByScreen = {
			xs: 1,
			sm: 2,
			md: 3,
			lg: 4
		}
		const applicationData = ref({
			course: 'NLE (Nursing)',
		});
		let loadingVisible = ref(false);
		// TODO: Load from server...
		const schoolsList = [ 'ILOILO', 'BACOLOD', 'PALAWAN', 'AKLAN', 'ROXAS', 'ANTIQUE', 'TUGUEGARAO', 'DIPOLOG', 'MANILA', 'DAVAO', 'GENSAN', 'NUEVA VIZCAYA' ];
		const coursesList = [ 'NLE (Nursing)' ];
		const branchListOptions = { dataSource: schoolsList };
		const schoolsListOptions = { dataSource: schoolsList };
		const courseListOptions = { dataSource: coursesList };
		const genderListOptions = { dataSource: [ 'Male', 'Female', 'Diverse' ] };
		const programListOptions = {
			grouped: true,
			displayExpr: 'name',
			valueExpr: 'id',
			dataSource: [
				{ key: 'Enhancement', items: [ { id: 'year3', name: '3rd year' }, { id: 'year4', name: '4th year' }, { id: 'year5', name: '5th year' }, { id: 'year6', name: '6th year' } ] },
				{ key: 'Intensive', items: [ { id: 'take1', name: '1st taker' }, { id: 'takere', name: 'Retaker' } ] }
			]
		};

		const submitApplicationForm = async () => {
			let returnVal, err;
			loadingVisible.value = true;
			[ err, returnVal ] = await to($http.post('/application', applicationData.value));
			loadingVisible.value = false;
			if (err) {
				console.error(err);
				return;
			}
			console.log(returnVal);
		}

		const fileUploadChanged = (e, dataField) => {
			applicationData.value[dataField] = e.value;
		}

		const needsBoardExamDate = computed(() => {
			return 'review_program' in applicationData.value && String(applicationData.value['review_program']).startsWith('take');
		});

		return {
			colCountByScreen,
			applicationData,
			submitApplicationForm,
			loadingVisible,
			fileUploadChanged,
			schoolsListOptions,
			branchListOptions,
			courseListOptions,
			genderListOptions,
			programListOptions,
			needsBoardExamDate
		};
	},
	components: {
		DxForm, DxSimpleItem, DxGroupItem, DxButtonItem, DxEmptyItem, DxRequiredRule, DxEmailRule, DxLoadPanel, DxFileUploader, DropzoneFile,
		DxTextArea, DxSelectBox, DxDateBox // eslint-disable-line vue/no-unused-components
	}
};
</script>

<style>
.header-image {
	width: 100%;
	max-width: 1200px;
}
.header-title {
	text-align: center;
	text-transform: uppercase;
	width: 100%; max-width: 1200px;
	border-bottom: 1px solid black;
}
.header-subtitle {
	text-align: center;
	width: 100%; max-width: 1200px;
	font-size: 20px;
}
.application-form {
	max-width: 1200px;
}
</style>
