<template>
	<div>
		<h2 class="content-block">Dashboard</h2>
		<div class="content-block">
			<div class="dx-card dashboard-card">
				<div class="header">At a glance</div>
				<div style="display: flex; flex-direction: row; align-items: center;">
					<div style="width:200px;">
						<div style="text-align:center;margin-bottom:5px;margin-top:15px;">Previous year applications</div>
						<DxSparkline class="header-subchart sparkline" :data-source="[{month:1,2010:7341,2011:9585,2012:7501},{month:2,2010:7016,2011:10026,2012:8470},{month:3,2010:7202,2011:9889,2012:8591},{month:4,2010:7851,2011:9460,2012:8459},{month:5,2010:7481,2011:9373,2012:8320},{month:6,2010:6532,2011:9108,2012:7465},{month:7,2010:6498,2011:9295,2012:7475},{month:8,2010:7191,2011:9834,2012:7430},{month:9,2010:7596,2011:9121,2012:7614},{month:10,2010:8057,2011:7125,2012:8245},{month:11,2010:8373,2011:7871,2012:7727},{month:12,2010:8636,2011:7725,2012:7880}]" :show-min-max="true" value-field="2010" argument-field="month" type="area" lineColor="#ffaa66" style="width:200px;height:50px;">
							<DxTooltip format="currency"/>
						</DxSparkline>
						<div style="display:flex;justify-content:space-between;padding:0px 13px;margin-bottom:5px;"><div>Region A</div><div>2370 -- 3100</div></div>
						<DxSparkline class="header-subchart sparkline" :data-source="[{month:1,2010:7341,2011:9585,2012:7501},{month:2,2010:7016,2011:10026,2012:8470},{month:3,2010:7202,2011:9889,2012:8591},{month:4,2010:7851,2011:9460,2012:8459},{month:5,2010:7481,2011:9373,2012:8320},{month:6,2010:6532,2011:9108,2012:7465},{month:7,2010:6498,2011:9295,2012:7475},{month:8,2010:7191,2011:9834,2012:7430},{month:9,2010:7596,2011:9121,2012:7614},{month:10,2010:8057,2011:7125,2012:8245},{month:11,2010:8373,2011:7871,2012:7727},{month:12,2010:8636,2011:7725,2012:7880}]" :show-min-max="true" value-field="2011" argument-field="month" type="area" lineColor="#0B4F6C" style="width:200px;height:50px;">
							<DxTooltip format="currency"/>
						</DxSparkline>
						<div style="display:flex;justify-content:space-between;padding:0px 13px;margin-bottom:5px;"><div>Region B</div><div>1567 -- 1855</div></div>
						<DxSparkline class="header-subchart sparkline" :data-source="[{month:1,2010:7341,2011:9585,2012:7501},{month:2,2010:7016,2011:10026,2012:8470},{month:3,2010:7202,2011:9889,2012:8591},{month:4,2010:7851,2011:9460,2012:8459},{month:5,2010:7481,2011:9373,2012:8320},{month:6,2010:6532,2011:9108,2012:7465},{month:7,2010:6498,2011:9295,2012:7475},{month:8,2010:7191,2011:9834,2012:7430},{month:9,2010:7596,2011:9121,2012:7614},{month:10,2010:8057,2011:7125,2012:8245},{month:11,2010:8373,2011:7871,2012:7727},{month:12,2010:8636,2011:7725,2012:7880}]" :show-min-max="true" value-field="2012" argument-field="month" type="area" lineColor="#9F9FED" style="width:200px;height:50px;">
							<DxTooltip format="currency"/>
						</DxSparkline>
						<div style="display:flex;justify-content:space-between;padding:0px 13px;margin-bottom:5px;"><div>Region C</div><div>1237 -- 1980</div></div>
					</div>
					<div style="width: 100%;">
					<DxPieChart
						id="pie"
						:data-source="areas"
						palette="Bright"
						title=""
						>
						<DxSeries
						argument-field="country"
						value-field="area"
						>
						<DxLabel :visible="true">
						<DxConnector
						:visible="true"
						:width="1"
						/>
						</DxLabel>
						</DxSeries>
						<DxSize :width="500"/>
						<DxExport :enabled="true"/>
					</DxPieChart>
					</div>
				</div>
				<div style="width: 100%;">
					<DxChart
					:data-source="dataSource"
					palette="Harmony Light"
					title="Applications by region, 2024"
					>
					<DxCommonSeriesSettings
					type="stackedarea"
					argument-field="date"
					/>
					<DxSeries
					value-field="regA"
					name="Region A"
					/>
					<DxSeries
					value-field="regB"
					name="Region B"
					/>
					<DxSeries
					value-field="regC"
					name="Region C"
					/>
					<DxMargin :bottom="20"/>
					<DxArgumentAxis :value-margins-enabled="false"/>
					<DxLegend
					vertical-alignment="bottom"
					horizontal-alignment="center"
					/>
					<DxExport :enabled="true"/>
					</DxChart>
				</div>
			</div>
			<div class="dx-card dashboard-card">
				<div class="header">Historical</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DxTooltip } from 'devextreme-vue/chart';
import DxSparkline from 'devextreme-vue/sparkline';
import {
DxChart,
DxSeries,
DxArgumentAxis,
DxCommonSeriesSettings,
DxExport,
DxLegend,
DxMargin,
} from 'devextreme-vue/chart';
import DxPieChart, {
  DxSize,
  DxLabel,
  DxConnector,
} from 'devextreme-vue/pie-chart';

export default {
	setup() {
		
		const dataSource = [{
		date: '2024/01',
		regA: 170,
		regB: 100,
		regC: 300,
		}, {
		date: '2024/02',
		regA: 270,
		regB: 900,
		regC: 350,
		}, {
		date: '2024/03',
		regA: 400,
		regB: 200,
		regC: 360,
		}, {
		date: '2024/04',
		regA: 600,
		regB: 300,
		regC: 370,
		}, {
		date: '2024/05',
		regA: 700,
		regB: 400,
		regC: 380,
		}, {
		date: '2024/06',
		regA: 800,
		regB: 550,
		regC: 1800,
		}];

		const areas = [{
		country: 'Complete',
		area: 82,
		}, {
		country: 'Waiting',
		area: 7,
		}, {
		country: 'Faulty',
		area: 11,
		}, {
		country: 'Pending Payment',
		area: 21,
		}, {
		country: 'Requires Help',
		area: 6,
		}];


		return {
			dataSource,
			areas,
		};
	},
	components: {
		DxSparkline,
		DxTooltip,
		DxChart,
		DxSeries,
		DxArgumentAxis,
		DxCommonSeriesSettings,
		DxExport,
		DxLegend,
		DxMargin,
		DxSize,
		DxLabel,
		DxConnector,
		DxPieChart
	}
};
</script>

<style lang="scss">
.dashboard-card {
	margin: 1em 0;
	padding: 20px;

	.header {
		font-weight: 500;
		font-size: 18px;
	}
}
</style>
