<template>
	<dx-scroll-view height="100%" width="100%" class="with-footer single-card">
		<div class="dx-card content">
			<div class="logo-header">
				<img src="@/assets/brainhub_logo.png" />
			</div>
			<div class="brand-header">
				<div class="title">BrainHub</div>
				<div class="subtitle">Review Specialists</div>
				<hr class="subtitle-rule">
			</div>
			<div class="header">
				<hr class="header-seperator">
				<div class="title">{{title}}</div>
				<div class="description">{{description}}</div>
			</div>
			<slot />
		</div>
	</dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";
import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

export default {
	components: {
		DxScrollView
	},
	setup() {
		const route = useRoute();
		const title = ref(route.meta.title);
		const description = ref("");

		watch(() => route.path, () => {
			title.value = route.meta.title;
			description.value = route.meta.description;
		});
		return { title, description };
	}
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.single-card {
	width: 100%;
	height: 100%;

	background-image: url(../assets/blur_office2.jpg);
	background-size: cover;
	background-position: center;

	.dx-card {
		width: 330px;
		margin: auto auto;
		padding: 40px;
		flex-grow: 0;

		.screen-x-small & {
			width: 100%;
			height: 100%;
			border-radius: 0;
			box-shadow: none;
			margin: 0;
			border: 0;
			flex-grow: 1;
		}
		.logo-header {
			width: 150px;
			height: 150px;
			position: absolute;
			margin-top: -125px;
			margin-left: 50px;
			img {
				width: 100%;
				border-radius: 100px;
				box-shadow: 0 10px 3px rgba(0, 0, 0, 0.12), 0 5px 3px rgba(0, 0, 0, 0.24)
			}
			.screen-x-small & {
				position: initial;
				margin: 0 auto;
			}
		}
		.brand-header {
			margin: 2em 0;
			color: $base-text-color;
			text-align: center;

			.title {
				text-transform: uppercase;
				font-weight: 800;
				font-size: 32px;
				line-height: 38px;
				color: $base-accent;
				-webkit-text-stroke: 1px rgba(0,0,0,.38);
				text-shadow: 0 10px 3px rgba(0, 0, 0, 0.12), 0 5px 3px rgba(0, 0, 0, 0.24);
			}
			.subtitle {
				font-weight: 500;
				font-size: 20px;
				line-height: 28px;
				padding: 0 10px;
				display: inline-block;
				position: relative;
				background: $base-bg;
				z-index: 1
			}
			.subtitle-rule {
				margin-top: -1em;
				border-top-color: $base-text-color;
			}
		}
		.header {
			margin-bottom: 0.5em;

			.header-seperator {
				margin-left: -40px;
				margin-right: -40px;
				margin-bottom: 1em;
				border: 0;
				border-top: 1px solid rgba($base-text-color, alpha($base-text-color) * 0.4);
			}
			.title {
				color: $base-text-color;
				line-height: 28px;
				font-weight: 500;
				font-size: 24px;
			}
			.description {
				color: rgba($base-text-color, alpha($base-text-color) * 0.7);
				line-height: 18px;
			}
		}
	}
}
</style>
