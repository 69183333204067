<template>
	<div>
		<h2 class="content-block">Application Management</h2>

		<dx-data-grid ref="datagridComponent" style="width:100%;" class="dx-card wide-card"
			:data-source="dataSourceConfig"
			:focused-row-index="0"
			:show-borders="false"
			:focused-row-enabled="true" :focusedRowIndex="-1"
			:column-auto-width="false"
			:column-hiding-enabled="true"
			:allowColumnResizing="true" columnResizingMode="widget"
			@exporting="onExporting"
		>
			<dx-state-storing :enabled="false" type="localStorage" storage-key="manage-applicants" />
			<dx-column-chooser :enabled="true" mode="select" :searchEnabled="true" height="400px">
				<dx-position my="right top" at="right bottom" of=".dx-datagrid-column-chooser-button" />
			</dx-column-chooser>
			<dx-paging :page-size="defaultPagerSize" />
			<dx-pager :show-page-size-selector="true" :show-info="true" :allowed-page-sizes="[5, 10, 15, 20, 50]" />
			<dx-filter-row :visible="true" />
			<dx-header-filter :visible="true" />
			<dx-search-panel :visible="true" width="300px" />
			<dx-toolbar>
				<dx-item location="before" name="groupBySwitcher">
					<dx-button icon="hierarchy" hint="Group by school"
						styling-mode="contained" :type="groupBySchool ? 'success' : 'default'"
						@click="groupBySwitcher"
					/>
				</dx-item>
				<dx-item location="before" name="groupPanel" />
				<dx-item location="before" name="searchPanel" />
				<dx-item location="after" name="refreshButton">
					<dx-button icon="refresh" hint="Refresh data" @click="refreshButton" />
				</dx-item>
				<dx-item location="after" name="exportButton" />
				<dx-item location="after" name="columnChooserButton" />
			</dx-toolbar>
			<dx-scrolling :useNative="true" />
			<dx-export :enabled="true" :formats="['xlsx', 'pdf']" />
			<dx-editing :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="form" />
			<dx-grouping :context-menu-enabled="false" expand-mode="rowClick" :autoExpandAll="false" />
			<dx-group-panel :visible="true" :allow-column-dragging="false" />
			<dx-master-detail :enabled="true" template="detail" />

			<template #detail="{ data }">
				<DxTabPanel>
					<DxItem title="Application" icon="floppy">
						<div>
							<div style="border: 1px solid black; margin: 20px; display:inline-block;">
								<img src="https://devxene.cirii.org/storage/test_employee.png" width="200" />
							</div>
							<div style="margin: 20px; display:inline-block;">
								<div>
									<span class="fieldName">External ID:</span> {{ data.data.external_id }}
								</div>
								<div>
									<span class="fieldName">Email:</span> <a :href="'mailto:' + data.data.email">{{ data.data.email }}</a>
								</div>
								<div>
									<span class="fieldName">Facebook:</span> <a :href="'https://www.facebook.com/' + data.data.facebook" target="_blank">{{ data.data.facebook }}</a>
								</div>
								<div>
									<span class="fieldName">Mobile:</span> <a :href="'skype:' + data.data.mobile">{{ data.data.mobile }}</a>
								</div>
							</div>
						</div>
					</DxItem>
					<DxItem title="Accounting" icon="money">
						<div style="margin: 20px 20px 20px 10px;border-left:5px solid #A0A0A0;padding: 20px 0 20px 10px;">
							<b>Payment status unknown.</b><br>
							<i>No payment or accounting details available.</i>
						</div>
					</DxItem>
					<DxItem title="Notes" icon="comment">
						<div style="margin: 20px 20px 20px 10px;border-left:5px solid #A0A0A0;padding: 20px 0 20px 10px;white-space:pre-line;">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a risus eget turpis sollicitudin pulvinar. Vivamus augue urna, pulvinar non elit at, vestibulum porttitor tortor. Quisque ac dui blandit, pulvinar velit eget, tempor odio. Pellentesque dignissim nunc et tellus fringilla egestas vel a orci. Mauris feugiat, augue et accumsan dapibus, sapien ex mattis metus, eget congue quam urna id quam. Aenean justo tellus, suscipit id sem vitae, vehicula fringilla orci. Duis id euismod nulla, ut viverra mi. Ut maximus, felis vitae varius pulvinar, magna massa ultrices ante, ac tincidunt lectus sapien et felis. Mauris consectetur condimentum laoreet. Proin quis libero non ipsum porta varius. Ut iaculis lobortis diam elementum euismod.
						</div>
					</DxItem>
					<DxItem title="History" icon="columnfield">
						<div style="margin:20px; padding:20px; max-width:800px;">
							<div style="border: 1px solid gray; padding: 10px; margin-bottom: 10px;background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);">
								[2024/07/10 13:00] Application submitted
								<span style="display:block;float:right"><dx-button height="18px" icon="chevrondown" /></span>
							</div>
							<div style="border: 1px solid gray; padding: 10px; margin-bottom: 10px;background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);">
								[2024/07/10 11:45] Application updated, file uploaded
								<span style="display:block;float:right"><dx-button height="18px" icon="chevrondown" /></span>
							</div>
							<div style="border: 1px solid gray; padding: 10px; margin-bottom: 10px;background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);">
								[2024/07/10 11:30] Application created
								<span style="display:block;float:right"><dx-button height="18px" icon="chevrondown" /></span>
							</div>
						</div>
					</DxItem>
				</DxTabPanel>
			</template>

			<template #facebookLinkTemplate="{ data }">
				<span>{{ data.value }}</span>
				<span v-if="facebookIdRegex.test(String(data.value).trim())">
					<a :href="'https://facebook.com/' + data.value" target="_blank"><dx-button height="18px" icon="link" /></a>
				</span>
			</template>

			<dx-column data-field="id" data-type="number" caption="AppID" :width="90" :allow-header-filtering="false" />
			<dx-column data-field="updated_at" data-type="datetime" caption="Last changed" sortOrder="desc" :width="160" :allow-header-filtering="false" />
			<dx-column data-field="school" caption="School" :width="150" :allow-header-filtering="true" :group-index="groupBySchool ? 1 : undefined" />
			<dx-column data-field="course" caption="Course" :width="100" :allow-header-filtering="true" />
			<dx-column caption="Name">
				<dx-column data-field="first_name" caption="First" :width="100" :allow-header-filtering="false" />
				<dx-column data-field="middle_name" caption="Middle" :width="100" :allow-header-filtering="false" />
				<dx-column data-field="last_name" caption="Last" :width="100" :allow-header-filtering="false" />
			</dx-column>
			<dx-column data-field="mobile" caption="Mobile" :width="130" :allow-header-filtering="false" />
			<dx-column data-field="email" caption="Email" :width="190" :allow-header-filtering="false" />
			<dx-column data-field="facebook" caption="Facebook Messenger" :width="190" cell-template="facebookLinkTemplate" :allow-header-filtering="false" />
			<dx-column data-field="address" caption="Address" :width="250" :allow-header-filtering="false" />
			<dx-column caption="Emergency Contact">
				<dx-column data-field="emergency_phone" caption="E. Phone" :width="130" :allow-header-filtering="false" />
				<dx-column data-field="emergency_name" caption="E. Name" :width="130" :allow-header-filtering="false" />
				<dx-column data-field="emergency_relationship" caption="Relation" :width="130" :allow-header-filtering="true" />
				<dx-column data-field="emergency_address" caption="E. Address" :width="130" :allow-header-filtering="false" />
			</dx-column>
			<dx-column data-field="created_at" data-type="datetime" caption="Submitted at" :width="160" :allow-header-filtering="false" />
		</dx-data-grid>
	</div>
</template>

<script>
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid, { DxColumn, DxFilterRow, DxPager, DxPaging, DxColumnChooser, DxPosition, DxStateStoring, DxToolbar, DxItem, DxSearchPanel,
	DxScrolling, DxHeaderFilter, DxExport, DxEditing, DxGrouping, DxGroupPanel, DxMasterDetail
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { inject, ref } from "vue";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import devices from "devextreme/core/devices"

export default {
	setup() {
		const datagridComponent = ref(null);			// Template ref for our main datagrid.
		const $http = inject('$http');
		const dataSourceConfig = new CustomStore({
			key: 'id',
			loadMode: 'raw',
			remoteOperations: false,
			load: () => {
				return $http.get('/application').then(response => {
					for (var rIdx in response.data) {
						response.data[rIdx].course = 'NLE';
					}
					return response.data;
				});
			}
		});
		const onExporting = (e) => {
			let dateCode = (new Date()).toISOString().substring(0, 10);
			if (e.format == 'xlsx') {
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('Main sheet');
				exportDataGrid({
					component: e.component,
					worksheet: worksheet,
					customizeCell: function(options) {
						options.excelCell.font = { name: 'Arial', size: 12 };
						options.excelCell.alignment = { horizontal: 'left' };
					} 
				}).then(function() {
					workbook.xlsx.writeBuffer()
						.then(function(buffer) {
							saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Applicants-' + dateCode + '.xlsx');
						});
				});
			} else if (e.format == 'pdf') {
				const doc = new jsPDF();
				exportDataGridToPdf({
					jsPDFDocument: doc,
					component: e.component
				}).then(() => {
					doc.save('Applicants-' + dateCode + '.pdf');
				});
			}
		};

		// Allow toggle button for automatically switching to group-by school column - don't allow other grouping selections.
		const groupBySchool = ref(false);
		const groupBySwitcher = () => {
			groupBySchool.value = !groupBySchool.value;
		}

		// Use 10 rows by default for phones instead of 15. 
		const defaultPagerSize = devices.current().deviceType == 'phone' ? 10 : 15;

		// Regex to detect valid facebook ID's where we can show a link.
		const facebookIdRegex = /^[a-z0-9\\.]+$/i;

		// Refresh datagrid via internal DevExtreme methods.
		const refreshButton = () => {
			datagridComponent.value.instance.refresh();
		}

		return { dataSourceConfig, onExporting, groupBySwitcher, groupBySchool, defaultPagerSize, facebookIdRegex, refreshButton, datagridComponent };
	},
	components: {
		DxDataGrid, DxColumn, DxFilterRow, DxPager, DxPaging, DxColumnChooser, DxPosition, DxStateStoring, DxToolbar, DxItem, DxButton,
		DxSearchPanel, DxScrolling, DxHeaderFilter, DxExport, DxEditing, DxGrouping, DxGroupPanel, DxMasterDetail, DxTabPanel
	}
};
</script>

<style>
.fieldName {
	display: inline-block;
	width: 100px;
	margin: 0.5em 0;
	font-weight: bold;
}
</style>