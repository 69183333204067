export default [
	{
		text: "Dashboard",
		path: "/home",
		icon: "home"
	},
	{
		text: "Application Form",
		path: "/application",
		icon: "product"
	},
	{
		text: "Management",
		icon: "folder",
		items: [
			{
				text: "Applicants",
				path: "/manage"
			},
			{
				text: "Employees",
				path: "/employees"
			}
		]
	}
];
