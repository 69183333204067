import appInfo from "./app-info";
import xior from 'xior';

let AuthSystem = {};

// The authentication section here uses a manual xior instance instead of the provide/inject version because
// we don't have access to setup() or anything else where we could use inject from Vue.
const $http = xior.create({
	baseURL: (appInfo.baseURL.endsWith('/') ? appInfo.baseURL.substr(0, appInfo.baseURL.length - 1) : appInfo.baseURL),
	headers: { 'Accept': 'application/json' },
});

// Default user for testing and debugging.
const defaultUser = {
	email: 'sandra@example.com',
	avatarUrl: appInfo.storageURL + '/test_employee.png'
};
const overrideLogin = false;

// Support for remembering password and session - the first time Auth is checked (usually for loggedIn() checks),
// see if we have access to localStorage stored auth keys.
let startupUser = null;
let startupToken = null;
if (localStorage) {
	try {
		startupUser = localStorage.getItem(appInfo.localPrefix + 'User');
		startupUser = JSON.parse(startupUser);
		startupToken = localStorage.getItem(appInfo.localPrefix + 'Auth');
	} catch (ex) {
		startupUser = null;
		startupToken = null;
		console.error("Exception loading remembered user. Clearing login session.");
		localStorage.removeItem(appInfo.localPrefix + 'User');
		localStorage.removeItem(appInfo.localPrefix + 'Auth');
	}
}
// Start an immediate refresh check on our token if logged in already - just to make sure everything is OK.
// TODO: REFRESH API CALL!

// See: https://js.devexpress.com/Vue/Documentation/Guide/Vue_Components/Application_Template/#Authentication/Integrate_with_a_Back_End
AuthSystem = {
	// Note that _user & _token are not reactive!
	_user: overrideLogin ? defaultUser : (startupUser ? startupUser : null),
	_token: startupToken ? startupToken : null,

	loggedIn() {
		return !!this._user;
	},

	// Attempt login with given email/password. Returns { isOk, message, data }.
	async logIn(email, password) {
		try {
			await $http.get('/../sanctum/csrf-cookie');
			let csrfCookie = RegExp('XSRF-TOKEN' + "=[^;]+").exec(document.cookie);
			csrfCookie = decodeURIComponent(csrfCookie ? csrfCookie.toString().replace(/^[^=]+./, "") : "");
			let tokenRequest = await $http.post('/user/login', { 'email': email, 'password': password }, {
				headers: { 'X-XSRF-TOKEN': csrfCookie }
			});
			let userProfile = tokenRequest.data.user;
			userProfile['avatarUrl'] = defaultUser['avatarUrl'];
			this._user = userProfile;
			this._token = tokenRequest.data.token;
			localStorage.setItem(appInfo.localPrefix + 'User', JSON.stringify(userProfile));
			localStorage.setItem(appInfo.localPrefix + 'Auth', this._token);

			return {
				isOk: true,
				data: this._user,
			}
		}
		catch {
			return {
				isOk: false,
				message: "Authentication failed"
			};
		}
	},

	async logOut() {
		this._user = null;
		this._token = null;
		localStorage.removeItem(appInfo.localPrefix + 'User');
		localStorage.removeItem(appInfo.localPrefix + 'Auth');
	},

	async getUser() {
		try {
			// Send request
			console.log("Getting user...");
			return {
				isOk: true,
				data: this._user,
			};
		}
		catch {
			return {
				isOk: false
			};
		}
	},

	async resetPassword(email) {
		try {
			// Send request
			console.log(email);

			return {
				isOk: true
			};
		}
		catch {
			return {
				isOk: false,
				message: "Failed to reset password"
			};
		}
	},

	async changePassword(email, recoveryCode) {
		try {
			// Send request
			console.log(email, recoveryCode);

			return {
				isOk: true
			};
		}
		catch {
			return {
				isOk: false,
				message: "Failed to change password"
			}
		}
	},

	async createAccount(email, password) {
		try {
			// Send request
			console.log(email, password);

			return {
				isOk: true
			};
		}
		catch {
			return {
				isOk: false,
				message: "Failed to create account"
			};
		}
	}
};
export default AuthSystem;